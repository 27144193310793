import React from 'react'
import PropTypes from 'prop-types'

import MarkStyled from './styled'

export const markImages = {
  lightYellowLonger: 'light-yellow-longer',
  lightYellowNormal: 'light-yellow-normal',
  sandLonger: 'sand-longer',
  sandNormal: 'sand-normal',
  sandSmall: 'sand-small',
  yellowLonger: 'yellow-longer',
  yellowNormal: 'yellow-normal',
}

const Mark = ({ image, children, ...props }) => (
  <MarkStyled {...{ image, ...props }}>{children}</MarkStyled>
)

Mark.propTypes = {
  image: PropTypes.oneOf(Object.values(markImages)),
  children: PropTypes.node.isRequired,
}

Mark.defaultProps = {
  image: markImages.yellowNormal,
}

export default Mark
