import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme, palette } from 'styled-tools'

import ProductPreview from '../ProductPreview'
import Columns from '../Columns'
import Container from '../Container'
import Button from '../Button'

import shopify from '../../../config/mapping/shopify'

const ButtonStyled = styled(Button)`
  width: 100%;
  margin: 1rem auto 10rem;
  text-align: center;
  background-color: ${palette('red')};

  &:not([disabled]):focus,
  &:not([disabled]):hover {
    background-color: ${palette('orange')};
  }
  
  ${theme('media.mdUp')} {
    width: 20.8rem;
  }
`

const MainList = ({ products }) => {
  products.sort(
    (a, b) =>
      a.metafields
        .filter((item) => item.namespace === 'front' && item.key === 'order')
        .map((item) => parseInt(item.value, 10))
        .shift()
      - b.metafields
        .filter((item) => item.namespace === 'front' && item.key === 'order')
        .map((item) => parseInt(item.value, 10))
        .shift()
  )

  return (
    <Container>
      <Columns>
        {products.map((product) => (
          <ProductPreview
            key={product.title}
            image={product.image.localFile}
            name={shopify.getCleanTitleFromShopifyTitle(product.title)}
            price={shopify.getMinPrice(product)}
            description={product.description}
            link={shopify.getFullProductPath(product.title)}
          />
        ))}
      </Columns>
      <ButtonStyled to="/produkte">Alle Produkte anzeigen</ButtonStyled>

    </Container>
  )
}

MainList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      image: PropTypes.any.isRequired,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          priceRangeV2: PropTypes.shape({
            minVariantPrice: PropTypes.shape({
              amount: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
}

export default MainList
