import styled from 'styled-components'

const MarkStyled = styled.mark`
  overflow: visible;
  word-break: initial;
  background-color: unset;
  background-image: ${({ image }) => `url("/images/marks/mark-${image}.svg")`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-decoration-break: clone;
`

export default MarkStyled
