import * as React from 'react'
import PropTypes from 'prop-types'

import AccessoriesList from '../AccessoriesList'
import MainList from './MainList'
import FilterGrid from './FilterGrid'

export const productTypes = {
  ACCESSORIES: 'accessories',
  MAIN: 'main',
  FILTER: 'filter',
}

const ProductList = ({ type, products, location }) => {
  switch (type) {
    case productTypes.ACCESSORIES:
      return <AccessoriesList location={location} />

    case productTypes.MAIN:
      return <MainList products={products} />

    case productTypes.FILTER:
      return <FilterGrid products={products} />

    default:
      return null
  }
}

ProductList.propTypes = {
  type: PropTypes.oneOf(Object.values(productTypes)).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      path: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}),
          }).isRequired,
        })
      ),
    })
  ),
  location: PropTypes.shape({}),
}

ProductList.defaultProps = {
  products: [],
  location: {},
}

export default ProductList
