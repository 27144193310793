import React from 'react'
import PropTypes from 'prop-types'
import SvgOverlay from './styled'

const Pattern = ({
  width, top, right, left, transformX, zIndex, children,
}) => (
  <SvgOverlay
    {...{
      width,
      top,
      right,
      left,
      transformX,
      zIndex,
    }}
  >
    {children}
  </SvgOverlay>
)

Pattern.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  transformX: PropTypes.string,
  zIndex: PropTypes.string,
}

Pattern.defaultProps = {
  width: '100%',
  top: '0',
  right: null,
  left: null,
  transformX: '0',
  zIndex: '10',
}

export default Pattern
