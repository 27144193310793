import styled from 'styled-components'
import { rgba } from 'polished'
import { theme, palette, withProp } from 'styled-tools'
import { Link } from 'gatsby'

import Heading from '../Heading'
import Paragraph from '../Paragraph'

export const CardHeading = styled(Heading)`
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  color: ${palette('black', 1)};
  font-weight: bold;
  font-size: 1.5rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 1.25;
  text-decoration: none;

  ${theme('media.mdUp')} {
    font-size: 2rem;
  }
`
export const CardHeadingLink = CardHeading.withComponent(Link)

export const Card = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 4rem;
  text-decoration: none;
  transition: ${theme('transition.base')};


  &:hover {
    & img {
      transform: scale(1.05);
      transition: ${theme('transition.base')};
    }

    .heading{
      text-decoration: underline;
    }
  }
`
export const CardLink = Card.withComponent(Link)

export const ImageWrapper = styled.div`
  width: 100%;
  transition: ${theme('transition.base')};
`

export const ImageWrapperLink = ImageWrapper.withComponent(Link)

export const Description = styled(Paragraph)`
  height: auto;
  margin-bottom: 1.5rem;
  line-height: 1.5;

  ${theme('media.mdDown')} {
    text-align: inherit;
  }
`
export const MoreDetails = styled(Link)`
  position: relative;
  display: block;
  width: fit-content;
  color: ${palette('black', 1)};
  font-weight: 700;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 2.5;
  letter-spacing: 1.25px;
  text-decoration: none;

  &:hover {
    color: ${withProp(palette('black', 1), (color) => rgba(color, 0.8))};
  }

  &:after {
    position: absolute;
    bottom: 4px;
    left: 0px;
    width: 100%;
    border-bottom: 2px solid #f2e2a9;
    content: '';
  }
`

export const PriceInfo = styled(Paragraph)`
  margin-top: 1.5rem;
  border-bottom: 2px solid ${palette('yellow', 1)};
`
export const Price = styled.span`
  color: ${palette('black', 1)};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
`
