import styled from 'styled-components'
import { theme } from 'styled-tools'

const SvgOverlay = styled.div`
  display: none;
  overflow: hidden;

  ${theme('media.lgUp')} {
    position: absolute;
    top: ${({ top }) => top};
    right: ${({ right }) => right};
    left: ${({ left }) => left};
    z-index: ${({ zIndex }) => zIndex};
    display: block;
    width: 40%;

    & svg {
      width: ${({ width }) => width};
      transform: ${({ transformX }) => `translateX(${transformX})`};
    }
  }
`

export default SvgOverlay
