import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import theme from '../../../config/theme'
import Column from '../Column'

import {
  CardLink, Description, CardHeadingLink, ImageWrapperLink, MoreDetails,
} from './styled'

import { priceFormatter } from '../../../utils/formatter'

const ProductPreview = ({
  image, name, price, description, link,
}) => (
  <Column>
    <CardLink to={link}>
      <ImageWrapperLink to={link}>
        <GatsbyImage image={getImage(image)} alt={name} />
      </ImageWrapperLink>
      <CardHeadingLink to={link} className="heading" level={2} font={theme.fonts.libreBaskerville}>
        {name}
      </CardHeadingLink>
      <Description length={description.length}>{description}</Description>
      <MoreDetails to={link}>Ab {priceFormatter(price)}€</MoreDetails>{' '}
    </CardLink>
  </Column>
)

ProductPreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default ProductPreview

export const query = graphql`
  fragment ProductPreview on ShopifyCollection {
    title
    description
    metafields {
      key
      namespace
      value
    }
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 1725,height:1392, placeholder: TRACED_SVG)
        }
      }
    }
    products {
      priceRangeV2 {
        minVariantPrice {
          amount
        }
      }
      title
    }
  }
`
