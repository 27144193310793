import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Columns from '../Columns'
import ProductPreview from '../ProductPreview'
import shopify from '../../../config/mapping/shopify'
import { FilterContainer, FilterOption, FilterTitle } from '../AccessoriesList/styled'

const TAG_ALL = 'Alles'

const FilterGrid = ({ products }) => {
  const [tags] = useState([TAG_ALL])

  const [activeFilterTag, setActiveFilterTag] = useState(TAG_ALL)

  products.forEach((item) => {
    item.metafields.forEach((m) => {
      if (m.key === 'category' && m.namespace === 'filter' && !tags.includes(m.value)) {
        tags.push(m.value)
      }
    })
  })

  useEffect(() => {
    let firstActive = typeof window !== 'undefined'
      ? decodeURIComponent(window.location.hash).substr(1)
      : `${tags[0]}`
    if (firstActive === '' || firstActive === '#') {
      firstActive = TAG_ALL
    }
    setActiveFilterTag(firstActive)
  }, [tags])

  const handleFilter = (tag) => {
    setActiveFilterTag(tag)
    window.location.hash = `#${encodeURIComponent(tag)}`
  }

  let availableProducts = products

  if (activeFilterTag !== TAG_ALL) {
    availableProducts = availableProducts.filter((product) => {
      const idx = product.metafields.findIndex(
        (item) => item.key === 'category' && item.namespace === 'filter'
      )
      return idx !== -1 && product.metafields[idx].value === activeFilterTag
    })
  }

  return (
    <>
      <FilterTitle>Filter</FilterTitle>
      <FilterContainer>
        {tags.map((tag) => (
          <FilterOption
            active={tag === activeFilterTag}
            onClick={() => handleFilter(tag)}
            key={tag}
          >
            {tag}
          </FilterOption>
        ))}
      </FilterContainer>
      <Columns>
        {availableProducts.map((product) => (
          <ProductPreview
            image={product.image.localFile}
            name={shopify.getCleanTitleFromShopifyTitle(product.title)}
            price={shopify.getMinPrice(product)}
            description={product.description}
            link={shopify.getFullProductPath(product.title)}
            key={product.title}
          />
        ))}
      </Columns>
    </>
  )
}

FilterGrid.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

FilterGrid.defaultProps = {}

export default FilterGrid
