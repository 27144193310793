import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import theme from '../config/theme'
import Page from '../components/layouts/Page'
import Container from '../components/parts/Container'
import Heading from '../components/parts/Heading'
import Pattern from '../components/parts/Pattern'
import SvgPattern from '../static/images/patterns/five.svg'
import ProductList, { productTypes } from '../components/parts/ProductList'

const HeadingContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 18rem;
  overflow: hidden;
  background-color: ${theme.palette.yellow[0]};
`

const HeadingStyled = styled(Heading)`
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme.fonts.libreBaskerville};
  letter-spacing: 1.25px;
  ${theme.media.smDown} {
    font-size: 2rem;
    letter-spacing: 0.8px;
  }
`

const ContainerStyled = styled(Container)`
  z-index: 9;
  transform: translateY(-13rem);
  ${theme.media.smDown} {
    transform: translateY(-17rem);
  }
`

const Products = ({
  data: {
    collections: { products: items },
  },
}) => {
  const sortedItems = items.sort(
    (a, b) =>
      a.metafields
        .filter((item) => item.key === 'order')
        .map((item) => parseInt(item.value, 10))
        .shift()
      - b.metafields
        .filter((item) => item.key === 'order')
        .map((item) => parseInt(item.value, 10))
        .shift()
  )

  return (
    <Page title="Produkte" description="" backgroundColor={theme.palette.yellow[0]}>
      <HeadingContainer>
        <Pattern top="2rem" right="0" transformX="25%" zIndex="1" width="95%">
          <SvgPattern />
        </Pattern>
        <Container>
          <HeadingStyled level={1}>Produkte</HeadingStyled>
        </Container>
      </HeadingContainer>
      <ContainerStyled>
        <ProductList products={sortedItems} type={productTypes.FILTER} />
      </ContainerStyled>
    </Page>
  )
}

Products.propTypes = {
  data: PropTypes.shape({
    collections: PropTypes.shape({
      products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
}

export default Products

export const query = graphql`
  {
    collections: allShopifyCollection(
      filter: { title: { regex: "/^(NEW)?PROD_/" } }
      sort: {fields: updatedAt, order: DESC}
    ) {
      products: nodes {
        ...ProductPreview
      }
    }
  }
`
